<template>
    <div class="row">
        <div class="col-12">
        <!-- Speaking -->
         <app-ReadAloud :mockId="mockId" :id="questionNumber" ref="readAloud" :currentQuestion="selectedQuestion" v-if="currentModule=='readAloud'"/>
         <app-AnswerShortQuestion :mockId="mockId" :id="questionNumber" ref="answerShortQuestion" :currentQuestion="selectedQuestion" v-if="currentModule=='answerShortQuestion'"/>
         <app-DescibeImage :mockId="mockId"  ref="describeimage" :id="questionNumber" :currentQuestion="selectedQuestion" v-if="currentModule=='describeimage'"/>
         <app-RepeatSentence :mockId="mockId"  :id="questionNumber" ref="repeatsentence" :currentQuestion="selectedQuestion" v-if="currentModule=='repeatsentence'"/>
         <app-RetellLecture :mockId="mockId"  :id="questionNumber" ref="retellLecture" :currentQuestion="selectedQuestion" v-if="currentModule=='retellLecture'"/>
         <!-- Writting -->
         <app-Summarized :mockId="mockId"  :id="questionNumber" ref="summarized" :currentQuestion="selectedQuestion" v-if="currentModule=='summarized'"/>
         <app-Essay :mockId="mockId"  :id="questionNumber" ref="essay" :currentQuestion="selectedQuestion" v-if="currentModule=='essay'"/>
         <!-- Reading -->
        <app-MultipleSingle :mockId="mockId"  :currentOptions="currentOptions" :id="questionNumber" :currentQuestion="selectedQuestion" ref="mcqSingle" v-if="currentModule=='mcqSingle'"/>
        <app-MultipleDouble :mockId="mockId"  :currentOptions="currentOptions" :id="questionNumber" :currentQuestion="selectedQuestion" ref="mcqMultiple" v-if="currentModule=='mcqMultiple'"/>
        <app-ReOrder :mockId="mockId"  :id="questionNumber" :currentQuestion="selectedQuestion" ref="reOrderPara" v-if="currentModule=='reOrderPara'"/>
        <app-ReadingFill :mockId="mockId" :currentOptions="currentOptions" :id="questionNumber" :currentQuestion="selectedQuestion" ref="readFill" v-if="currentModule=='readFill'"/>
        <app-ReadingWriteFill :mockId="mockId" :currentOptions="currentOptions" :id="questionNumber" :currentQuestion="selectedQuestion" ref="readWriteFill"  v-if="currentModule=='readWriteFill'"/>
        <!-- Listening -->
        <app-SummarizedSpokenText :mockId="mockId"   :id="questionNumber" :currentQuestion="selectedQuestion" ref="summarizedSpoken"  v-if="currentModule=='summarizedSpoken'"/>
        <app-MultipleChoiceDouble :currentOptions="currentOptions" :mockId="mockId"  :id="questionNumber" :currentQuestion="selectedQuestion" ref="listMcqMultiple" v-if="currentModule=='listMcqMultiple'"/>
        <app-MultipleChoiceSingle :currentOptions="currentOptions" :mockId="mockId"  :id="questionNumber" :currentQuestion="selectedQuestion" ref="listMcqSingle" v-if="currentModule=='listMcqSingle'"/>
        <app-HighLightCorrectSummary :currentOptions="currentOptions" :mockId="mockId"  :id="questionNumber" :currentQuestion="selectedQuestion" ref="highLightSummary" v-if="currentModule=='highLightSummary'"/>
        <app-SelectMissingWord :currentOptions="currentOptions" :mockId="mockId"  :id="questionNumber" :currentQuestion="selectedQuestion" ref="selectMissingWords" v-if="currentModule=='selectMissingWords'"/>
        <app-FillBlanks :mockId="mockId"  :id="questionNumber" :currentQuestion="selectedQuestion" ref="listFillBlanks" v-if="currentModule=='listFillBlanks'"/>
        <app-HighLightCorrectWord :mockId="mockId"   :id="questionNumber" :currentQuestion="selectedQuestion" ref="highLightWords" v-if="currentModule=='highLightWords'"/>
        <app-WrittenDictation :mockId="mockId"  :id="questionNumber" :currentQuestion="selectedQuestion" ref="writeFromDict" v-if="currentModule=='writeFromDict'"/>
        <app-BreakPage v-if='currentModule=="break"' :questionType='currentModule'/>
        <app-FinishTest v-if="currentModule=='finish'" :user_mock_id='user_mock_id' :mockId='mock_Id' :user_id='user_id' :endModule="endModule"/>
        </div>
    </div>
</template> 
<script>
import { mapActions,mapGetters } from 'vuex'
// speaking
import ReadAloud from '../mockViews/speaking/m_readaloud'
import AnswerShortQuestion from '../mockViews/speaking/m_answershortquestion'
import DescibeImage from '../mockViews/speaking/m_describeimage'
import RepeatSentence from '../mockViews/speaking/m_repeatsentence'
import RetellLecture from '../mockViews/speaking/m_retelllecture'
// Writting
import Summarized from '../mockViews/writing/m_summarized'
import Essay from '../mockViews/writing/m_essay'
// reading
import MultipleSingle from '../mockViews/reading/m_multiplesingle'
import MultipleDouble from '../mockViews/reading/m_multipledouble'
import ReOrder from '../mockViews/reading/m_reorder'
import ReadingFill from '../mockViews/reading/m_readingfill'
import ReadingWriteFill from '../mockViews/reading/m_readingwrittingfill'
// listening
import MultipleChoiceSingle from '../mockViews/listening/m_multiplechoicesingle'
import MultipleChoiceDouble from '../mockViews/listening/m_multiplechoicedouble'
import FillBlanks from '../mockViews/listening/m_fillblanks'
import HighLightCorrectSummary from '../mockViews/listening/m_highlightcorrectsummary'
import HighLightCorrectWord from '../mockViews/listening/m_highlightincorrectword'
import SelectMissingWord from '../mockViews/listening/m_selectmissingword'
import SummarizedSpokenText from '../mockViews/listening/m_summarizedspoken'
import WrittenDictation from '../mockViews/listening/m_writtendictation'

import BreakPage from '../mockViews/questionStartInstructions'
import FinishTest from '../mockViews/mockTestFinish'

export default {
    components:{
    'app-ReadAloud': ReadAloud,
    'app-AnswerShortQuestion':AnswerShortQuestion,
    'app-DescibeImage':DescibeImage,
    'app-RepeatSentence':RepeatSentence,
    'app-RetellLecture':RetellLecture,
    'app-Summarized':Summarized,
    'app-Essay':Essay,
    'app-MultipleSingle':MultipleSingle,
    'app-MultipleDouble':MultipleDouble,
    'app-ReOrder':ReOrder,
    'app-ReadingFill':ReadingFill,
    'app-ReadingWriteFill':ReadingWriteFill,
    'app-MultipleChoiceSingle':MultipleChoiceSingle,
    'app-MultipleChoiceDouble':MultipleChoiceDouble,
    'app-FillBlanks':FillBlanks,
    'app-HighLightCorrectSummary':HighLightCorrectSummary,
    'app-HighLightCorrectWord':HighLightCorrectWord,
    'app-SelectMissingWord':SelectMissingWord,
    'app-SummarizedSpokenText':SummarizedSpokenText,
    'app-WrittenDictation':WrittenDictation,
    'app-BreakPage':BreakPage,
    'app-FinishTest':FinishTest
    },
    props:{
        mockId:{
            default:0,
            required: true
        },
        questionList:{
            default:null,
            required:true
        }
    },
    data(){
        return{
            currentModule:'readAloud',
            user_mock_id:this.mockId,
            user_id:null,
            mock_Id:null,
            currentQuestion:null,
            currentOptions:null,
            currentConfigOptions:null,
            questionNumber:0,
            selectedQuestion:null,
            setTime:false,
            reorderIDCheck:0,
            minQuestion:1,
            maxQuestions:1,
            partType:'speaking',
            callQuestionCounting:true,
            endModule:''
        }
    },
    methods:{
        ...mapActions({
            questionservice: 'mocktest/fetchQuestion',
            userMockSave:'usermocktest/saveMockForUser',
        }),
        ...mapGetters({
            getQuestionList: 'mocktest/questions',
            user_MockResponse:'usermocktest/UserMockResponse'
        }),
        countQuestions(){
            this.maxQuestions = 0
            this.minQuestion = 1
            this.callQuestionCounting=false
            if(this.partType=='speaking')
            {
                this.maxQuestions+=this.questionList.readAloud.length
                this.maxQuestions+=this.questionList.repeatsentence.length
                this.maxQuestions+=this.questionList.describeimage.length
                this.maxQuestions+=this.questionList.retellLecture.length
                this.maxQuestions+=this.questionList.answerShortQuestion.length
            }
            else  if(this.partType=='writing')
            {
                if(this.currentModule=='summarized'){
                    this.maxQuestions=this.questionList.summarized.length
                }
                else{
                    this.maxQuestions=this.questionList.essay.length
                    //console.log("Question count "+ this.maxQuestions)
                }
            }
            else if(this.partType == 'reading')
            {
                ////debugger
                this.maxQuestions+=this.questionList.mcqSingle.length
                this.maxQuestions+=this.questionList.mcqMultiple.length
                this.maxQuestions+=this.countParaGrapgQuest()
                this.maxQuestions+=this.questionList.readFill.length
                this.maxQuestions+=this.questionList.readWriteFill.length
            }
            else if(this.partType == 'listening')
            {
                this.maxQuestions+=this.questionList.listMcqMultiple.length
                this.maxQuestions+=this.questionList.listFillBlanks.length
                this.maxQuestions+=this.questionList.highLightSummary.length
                this.maxQuestions+=this.questionList.listMcqSingle.length
                this.maxQuestions+=this.questionList.selectMissingWords.length
                this.maxQuestions+=this.questionList.highLightWords.length
                this.maxQuestions+=this.questionList.writeFromDict.length
            }
            return this.maxQuestions
        },
        saveUserMockQuestions(quest){ // I will call this method in every question next
            ////debugger
            const data={
                    url:'usermocktest/insertusermocktest',
                    user_mock_id:this.user_mock_id,
                    question_id: quest.question_id, 
                    question_module:quest.question_module, 
                    question_status:quest.question_status
                    }
            this.userMockSave(data).then(()=>{
                const response=this.user_MockResponse()
                //console.log(response)
                if(response.success!=1){
                    this.userMockSave(data).then(()=>{
                        if(response.success!=1)this.userMockSave(data)
                    })
                }
            })
        },
        countParaGrapgQuest(){

            var array=this.questionList.reOrderPara
            var questID = array.map(function(obj) { return obj.question_id; });
            questID = questID.filter(function(v,i) { return questID.indexOf(v) == i; });
            return questID.length
        },
        loadQuestion(){
            console.log("load Question", this.currentModule);
            console.log("End Module", this.endModule);
            if(this.endModule==this.currentModule || this.endModule=="endModule"){
                this.questionNumber = 200 // as it is finishing the module wise test.
                this.changeQuestionType('module finish');
            }
            else if(this.currentModule=='readAloud')
            {
                if(this.callQuestionCounting) this.$parent.$parent.changeQuestionNumber(true,1,this.countQuestions());
                this.currentQuestion=this.questionList.readAloud
                
                this.changeQuestionType('repeatsentence')
                //console.log(this.currentQuestion)
            }
            else if(this.currentModule=='repeatsentence'){
                this.currentQuestion=this.questionList.repeatsentence
                this.changeQuestionType('describeimage')
                //console.log(this.currentQuestion)
            }
            else if(this.currentModule=='describeimage'){
                this.currentQuestion=this.questionList.describeimage
                this.changeQuestionType('retellLecture')
                //console.log(this.currentQuestion)
            }
            else if(this.currentModule=='retellLecture'){
                this.currentQuestion=this.questionList.retellLecture
                this.changeQuestionType('answerShortQuestion')
                //console.log(this.currentQuestion)
            }
            else if(this.currentModule=='answerShortQuestion'){
                this.currentQuestion=this.questionList.answerShortQuestion
                this.changeQuestionType('summarized')
                //console.log(this.currentQuestion)
            }
            else if(this.currentModule=='summarized'){
                if(this.partType !='writing'){
                    this.partType = 'writing'
                this.callQuestionCounting=true
                this.$ls.set('currentQuestionModule',null)
                }
                if(this.callQuestionCounting| this.$parent.$parent.maxQuestion==0) this.$parent.$parent.changeQuestionNumber(true,0,this.countQuestions())
                //this.$parent.$parent.changeQuestionNumber(true,1,1)
                this.$parent.$parent.changeTimeFromChild(10,0)
                this.currentQuestion=this.questionList.summarized
                this.changeQuestionType('essay')
                //console.log(this.currentQuestion)
            }
            else if(this.currentModule=='essay'){
                if(this.partType !='writing'){
                    this.partType = 'writing'
                this.callQuestionCounting=true
                }
                if(this.callQuestionCounting| this.$parent.$parent.maxQuestion==0) this.$parent.$parent.changeQuestionNumber(true,0,this.countQuestions())
                this.$parent.$parent.changeTimeFromChild(20,0)
                this.currentQuestion=this.questionList.essay
                this.changeQuestionType('mcqSingle')
                //console.log(this.currentQuestion)
            }
            else if(this.currentModule=='mcqSingle'){
                let firstQuestion=false;
                if(this.partType !='reading'){
                    this.partType = 'reading';
                    firstQuestion=true;
                this.callQuestionCounting=true
                if(this.$parent.$parent.lastSavedMinutes==0 & this.$parent.$parent.lastSavedSeconds==0) {
                   this.$parent.$parent.changeTimeFromChild(29,0);
                }
                else this.$parent.$parent.changeTimeFromChild(Number(this.$parent.$parent.lastSavedMinutes),Number(this.$parent.$parent.lastSavedSeconds));
                }
                if(this.callQuestionCounting| this.$parent.$parent.maxQuestion==0) this.$parent.$parent.changeQuestionNumber(true,0,this.countQuestions())
                
                this.currentQuestion=this.questionList.mcqSingle
                this.currentOptions=this.questionList.mcqSingleOptions
                this.changeQuestionType('mcqMultiple')
                //console.log(this.currentQuestion)
            }
            else if(this.currentModule=='mcqMultiple'){
                this.currentQuestion=this.questionList.mcqMultiple
                this.currentOptions=this.questionList.mcqMultipleOptions
                this.changeQuestionType('reOrderPara')
                //console.log(this.currentQuestion)
            }
            else if(this.currentModule=='reOrderPara'){
                this.currentQuestion=this.questionList.reOrderPara
                this.reOrderChangeQuestionType('readFill')
                //console.log(this.currentQuestion)
            }
            else if(this.currentModule=='readFill'){
                
                this.currentQuestion=this.questionList.readFill
                this.currentOptions=this.questionList.readFillOptions
                this.changeQuestionType('readWriteFill')
                //console.log(this.currentQuestion)
            }
            else if(this.currentModule=='readWriteFill'){
                this.currentQuestion=this.questionList.readWriteFill
                this.currentConfigOptions=this.questionList.readWriteFillIOptionsConfig
                this.currentOptions=this.questionList.readWriteFillIOptions
                // if(!this.$parent.$parent.testResume) this.changeQuestionType('break')
                // else 
                this.changeQuestionType('summarizedSpoken');
            }
            // else if(this.currentModule == 'break'){
                
            //     this.$parent.$parent.changeTimeFromChild(10)
            // }
            else if(this.currentModule=='summarizedSpoken'){
                this.$parent.$parent.changeQuestionNumber(true,0,1)
                 this.$parent.$parent.changeTimeFromChild(10,0)
                this.currentQuestion=this.questionList.summarizedSpoken
                this.changeQuestionType('listMcqMultiple')
                //console.log(this.currentQuestion)
            }
            else if(this.currentModule=='listMcqMultiple'){
                if(this.partType != 'listening'){
                    this.partType = 'listening'
                    this.callQuestionCounting=true
                }
                if(this.callQuestionCounting| this.$parent.$parent.maxQuestion==0) this.$parent.$parent.changeQuestionNumber(true,0,this.countQuestions())
                this.$parent.$parent.changeTimeFromChild(40,0)
                this.currentQuestion=this.questionList.listMcqMultiple
                this.currentOptions=this.questionList.listMcqMultipleOptions
                this.changeQuestionType('listFillBlanks')
                //console.log(this.currentQuestion)
            }
            else if(this.currentModule=='listFillBlanks'){
                this.currentQuestion=this.questionList.listFillBlanks
                this.changeQuestionType('highLightSummary') //highLightWords //highLightSummary //selectMissingWords
                //console.log(this.currentQuestion)
            }
            else if(this.currentModule=='highLightSummary'){
                this.currentQuestion=this.questionList.highLightSummary
                this.currentOptions=this.questionList.highLightSummaryOptions
                this.changeQuestionType('listMcqSingle')
                //console.log(this.currentQuestion)
            }
            else if(this.currentModule=='listMcqSingle'){
                this.currentQuestion=this.questionList.listMcqSingle
                this.currentOptions=this.questionList.listMcqSingleOptions
                this.changeQuestionType('selectMissingWords')
                //console.log(this.currentQuestion)
            }
            else if(this.currentModule=='selectMissingWords'){
                this.currentQuestion=this.questionList.selectMissingWords
                this.currentOptions=this.questionList.selectMissingWordsOptions
                this.changeQuestionType('highLightWords')
                //console.log(this.currentQuestion)
            }
            else if(this.currentModule=='highLightWords'){
                this.currentQuestion=this.questionList.highLightWords
                this.changeQuestionType('writeFromDict')
                //console.log(this.currentQuestion)
            }
            else if(this.currentModule=='writeFromDict'){
                this.currentQuestion=this.questionList.writeFromDict;
                
                this.changeQuestionType('finish')
                
                //console.log(this.currentQuestion)
            }
            else if(this.currentModule=='finish'){
                if(this.endModule=='speakingEndModule') {
                    this.endModule="endModule";
                    this.questionNumber = 200 // as it is finishing the test.
                    this.changeQuestionType('module finish')
                    }
                    else{
                        this.questionNumber = 200 // as it is finishing the test.
                        this.changeQuestionType('finish')
                    }
                
                //console.log(this.currentQuestion)
            }
        },

        changeQuestionType(type){
            this.selectedQuestion=this.currentQuestion[this.questionNumber];
            if(this.endModule=='speakingEndModule' & type=='finish') type="module finish";
            //console.log(this.selectedQuestion)
            this.$forceUpdate()
                if(this.questionNumber < this.currentQuestion.length){
                    this.questionNumber++
                }
                else if(type=='finish'){
                    this.currentModule = type
                    this.$parent.onQuestions=false 
                    this.$alert('Congratulation you finished your practice test your results will be ready in 30 minutes.','Congrats','success').then(()=>{
                                let routeData = this.$router.resolve({name: '/'});
                                window.open(routeData.href,"_self");
                            })
                }
                else if(type=='module finish'){
                    this.currentModule = 'finish'
                    this.$parent.onQuestions=false 
                    this.$alert('Click continue to proceed. You can check your scores or review your answers by clicking the buttons next to your attempted mock test.','Congratulations!',
                    'success',{
                                confirmButtonText: "continue"
                              }).then(()=>{
                                let routeData = this.$router.resolve({name: '/'});
                                window.open(routeData.href,"_self");
                            })
                }
                // else if(type=='break'){
                //     this.currentModule = type
                //     //this.loadQuestion()
                //     //console.log('break')
                // }
                else{
                    this.questionNumber=0
                    this.currentModule = type
                    this.loadQuestion()
                    
                }
        },
        reOrderChangeQuestionType(type){
            if(this.questionNumber >= this.currentQuestion.length )
            {
                 this.questionNumber=0
                    this.currentModule = type
                    return this.loadQuestion()
            }

            this.selectedQuestion=this.currentQuestion[this.questionNumber]
            const currentQuestionId=this.selectedQuestion.question_id
            this.selectedQuestion= this.currentQuestion.filter((item)=>{
                return item.question_id == currentQuestionId
            })
            //console.log(this.selectedQuestion)
            this.reorderIDCheck=(currentQuestionId !=this.reorderIDCheck)?currentQuestionId:0
                if(this.questionNumber < this.currentQuestion.length & currentQuestionId ==this.reorderIDCheck){
                    this.questionNumber+=this.selectedQuestion.length+1
                }
                else{
                    this.questionNumber=0
                    this.currentModule = type
                    this.loadQuestion()
                    
                }
        },
        callinParentForReconfirm(){
            this.$parent.$parent.updateUserMockTestQuestionId(this.selectedQuestion.question_id,this.currentModule)
        },
        async moveToNextMK(){
            console.log("Move to next", this.currentModule);
            if(this.currentModule!='finish' & this.currentModule!='break'){
                var questId=this.selectedQuestion.question_id
                ////debugger
                if(this.currentModule=="reOrderPara") questId = this.selectedQuestion[0].question_id
                var quest={
                    question_id:questId,
                    question_module:this.currentModule,
                    question_status:'Complete'
                }
                ////debugger
                this.saveUserMockQuestions(quest)
               this.$parent.$parent.updateUserMockTestQuestionId(questId,this.currentModule)
            }
            if(this.endModule==this.currentModule || this.endModule=="endModule"){
                this.$ls.set('currentQuestionModule',null)
                this.changeQuestionType('module finish');
            }
            else if(this.currentModule=='readAloud')
            {
                this.$ls.set('currentQuestionModule',this.currentModule)
                this.$refs.readAloud.moveToNextRA()
                this.loadQuestion()
                this.$refs.readAloud.changePresentQuestion(this.selectedQuestion)
            }
            else if(this.currentModule=='repeatsentence')
            {
                this.$ls.set('currentQuestionModule',this.currentModule)
                await this.$refs.repeatsentence.moveToNextRA()
                this.loadQuestion()
                this.$refs.repeatsentence.changePresentQuestion(this.selectedQuestion)
            }
            else if(this.currentModule=='describeimage')
            {
                this.$ls.set('currentQuestionModule',this.currentModule)
                await this.$refs.describeimage.moveToNextRA()
                this.loadQuestion()
                this.$refs.describeimage.changePresentQuestion(this.selectedQuestion)
            }
            else if(this.currentModule=='retellLecture')
            {
                this.$ls.set('currentQuestionModule',this.currentModule)
                await this.$refs.retellLecture.moveToNextRA()
                this.loadQuestion()
                this.$refs.retellLecture.changePresentQuestion(this.selectedQuestion)
            }
            else if(this.currentModule=='answerShortQuestion')
            {
                this.$ls.set('currentQuestionModule',this.currentModule)
                await this.$refs.answerShortQuestion.moveToNextRA()
                this.loadQuestion()
                this.$refs.answerShortQuestion.changePresentQuestion(this.selectedQuestion)
            }
            else if(this.currentModule=='summarized')
            {
                this.$ls.set('currentQuestionModule',null)
                this.$parent.$parent.changeTimeFromChild(10,0)
                this.loadQuestion()
                this.$refs.summarized.changePresentQuestion(this.selectedQuestion)
                
            }
            else if(this.currentModule=='essay')
            {
                this.$ls.set('currentQuestionModule',null)
                this.$parent.$parent.changeTimeFromChild(20,0)
                this.loadQuestion()
                this.$refs.essay.changePresentQuestion(this.selectedQuestion)
                
            }
            else if(this.currentModule=='mcqSingle')
            {
                this.$ls.set('currentQuestionModule',null)
                this.loadQuestion()
                this.$refs.mcqSingle.changePresentQuestion(this.selectedQuestion)
            }
            else if(this.currentModule=='mcqMultiple')
            {
                this.$ls.set('currentQuestionModule',null)
                this.loadQuestion()
                this.$refs.mcqMultiple.changePresentQuestion(this.selectedQuestion)
            }
            else if(this.currentModule=='reOrderPara')
            {
                this.$ls.set('currentQuestionModule',null)
                this.loadQuestion()
                this.$refs.reOrderPara.changePresentQuestion(this.selectedQuestion)
            }
            else if(this.currentModule=='readFill')
            {
                this.$ls.set('currentQuestionModule',null)
                this.loadQuestion()
                this.$refs.readFill.changePresentQuestion(this.selectedQuestion)
            }
            else if(this.currentModule=='readWriteFill')
            {
                this.$ls.set('currentQuestionModule',null)
                this.loadQuestion()
                this.$refs.readWriteFill.changePresentQuestion(this.selectedQuestion)
            }
            // else if(this.currentModule == 'break'){
            //     this.$ls.set('currentQuestionModule',null)
            //     this.currentModule='summarizedSpoken'
            //     this.loadQuestion()
            // }
            else if(this.currentModule=='summarizedSpoken')
            {
                this.$ls.set('currentQuestionModule',null)
                this.$parent.$parent.changeTimeFromChild(10,0)
                this.loadQuestion()
                this.$refs.summarizedSpoken.changePresentQuestion(this.selectedQuestion)
            }
            else if(this.currentModule=='listMcqMultiple')
            {
                this.$ls.set('currentQuestionModule',null)
                this.$parent.$parent.changeTimeFromChild(37,0)
                this.loadQuestion()
                this.$refs.listMcqMultiple.changePresentQuestion(this.selectedQuestion)
            }
            else if(this.currentModule=='listMcqSingle')
            {
                this.$ls.set('currentQuestionModule',null)
                this.loadQuestion()
                this.$refs.listMcqSingle.changePresentQuestion(this.selectedQuestion)
            }
            else if(this.currentModule=='highLightSummary')
            {
                this.$ls.set('currentQuestionModule',null)
                this.loadQuestion()
                this.$refs.highLightSummary.changePresentQuestion(this.selectedQuestion)
            }
            else if(this.currentModule=='selectMissingWords')
            {
                this.$ls.set('currentQuestionModule',null)
                this.loadQuestion()
                this.$refs.selectMissingWords.changePresentQuestion(this.selectedQuestion)
            }
            else if(this.currentModule=='listFillBlanks')
            {
                this.$ls.set('currentQuestionModule',null)
                this.loadQuestion()
                this.$refs.listFillBlanks.changePresentQuestion(this.selectedQuestion)
            }
            else if(this.currentModule=='highLightWords')
            {
                this.$ls.set('currentQuestionModule',null)
                this.loadQuestion()
                this.$refs.highLightWords.changePresentQuestion(this.selectedQuestion)
            }
            else if(this.currentModule=='writeFromDict')
            {
                this.$ls.set('currentQuestionModule',null)
                this.loadQuestion()
                this.$refs.writeFromDict.changePresentQuestion(this.selectedQuestion)
            }
        }
    },
    beforeMount(){
        let selectedModule=this.$ls.get('currentQuestionModule');
        this.currentModule=(selectedModule!=null)?selectedModule:this.currentModule;
        let endMod=this.$ls.get('endQuestionModule');
        this.endModule=(endMod!=null)?endMod:this.endModule;
        this.loadQuestion()
    },
    created(){
        this.mock_Id= this.$ls.get('mockTestId')
        this.user_id=this.$ls.get('user').user_id
        this.user_mock_id=this.$ls.get('user_mock_id')
    }
}
</script>
<style>
p
{
    color: #000!important;
}
</style>